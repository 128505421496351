import { NavLink, useLocation } from "react-router-dom";
import "./header.scss";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { NAVIGATION } from "@/shared/utils/navigation";
import { useAccount } from "@/shared/hooks";

const Header = () => {
  const location = useLocation();
  const account = useAccount();
  const componentRef = useSelector(
    (state: { printRef: { componentRef: React.ReactInstance | null } }) =>
      state.printRef.componentRef
  );

  const handlePrint = useReactToPrint({
    content: () => componentRef,
  });

  if (["/", "/glossary"].includes(location.pathname)) {
    return <div className="header-empty"></div>;
  }

  const activeNavigation = NAVIGATION.find(
    (navItem) =>
      navItem.path != "/" && location.pathname.startsWith(navItem.path)
  );

  const showPrinter = activeNavigation && activeNavigation.showPrinter;

  return (
    <header className="header-main">
      <nav>
        <ul>
          {activeNavigation
            ? activeNavigation.children?.map((childNav) => {
                const hasPermission =
                  !childNav.requiredRoles ||
                  childNav.requiredRoles.some(
                    (role) => account[role as keyof typeof account] === true
                  );

                if (!hasPermission) {
                  return null;
                }
                return (
                  <li key={childNav.path}>
                    <NavLink
                      to={childNav.path}
                      className={({ isActive, isPending }) =>
                        isActive ? "active" : isPending ? "pending" : ""
                      }
                      end
                    >
                      {childNav.title}
                    </NavLink>
                  </li>
                );
              })
            : null}
        </ul>
      </nav>
      <div className="header-middle"></div>
      {showPrinter ? (
        <div className="print">
          <a
            className={`${!componentRef ? "disabled" : ""}`}
            onClick={componentRef ? handlePrint : () => {}}
          >
            <i className="cs-icon icon-print"></i>
            <span>Print</span>
          </a>
        </div>
      ) : null}
    </header>
  );
};

export default Header;
