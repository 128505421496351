import * as React from "react";
import { useControllableState } from "@radix-ui/react-use-controllable-state";
import { Button, cn } from "@suns/design-system";

const NAME = "ThreeStateToggle";

type ThreeStateToggleElement = React.ElementRef<typeof Button>;
type ButtonProps = React.ComponentPropsWithoutRef<typeof Button>;

interface ThreeStateToggleProps extends Omit<ButtonProps, "onChange"> {
  /**
   * The controlled state of the toggle.
   */
  value?: number;
  /**
   * The state of the toggle when initially rendered. Use `defaultValue`
   * if you do not need to control the state of the toggle.
   * @defaultValue 0
   */
  defaultValue?: number;
  /**
   * The callback that fires when the state of the toggle changes.
   */
  onChange?(value: number): void;
}

const ThreeStateToggle = React.forwardRef<
  ThreeStateToggleElement,
  ThreeStateToggleProps
>((props, ref) => {
  const {
    value: prop,
    defaultValue: defaultProp,
    onChange,
    ...buttonProps
  } = props;

  const [value, setValue] = useControllableState({
    prop,
    onChange,
    defaultProp,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!event.defaultPrevented) {
      const toSet = typeof value === "number" ? value + 1 : 1;
      setValue(toSet > 2 ? 0 : toSet);
    }
  };

  return (
    <Button
      type="button"
      variant="muted"
      className={cn(
        "w-12 text-sm",
        value === 1 && "bg-secondary-300 hover:bg-secondary-300",
        value === 2 && "bg-secondary-600 text-white hover:bg-secondary-600"
      )}
      {...buttonProps}
      ref={ref}
      onClick={handleClick}
    />
  );
});

ThreeStateToggle.displayName = NAME;

export { ThreeStateToggle };
export type { ThreeStateToggleProps };
