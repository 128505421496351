import { useForm } from "react-hook-form";
import {
  Button,
  TextEditor,
  Flex,
  Textarea,
  Text,
  FormItem,
  Form,
  FormControl,
  FormField,
  FormLabel,
} from "@suns/design-system";
import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  PlayerRow,
  ReportApolloGrade,
  ReportPlayerAttribute,
  ReportResponseItem,
  ReportUpsertParams,
  TeamRow,
} from "@suns/api/generated-client/apollo";
import { ScoreboardGame } from "@suns/api/generated-client/stats";
import { z } from "zod";
import { toast } from "react-toastify";
import {
  ApolloGradeValueLabels,
  DefenseOffBallScores,
  DefenseOnBallScores,
  OffenseShootingScores,
  OffenseSkillScores,
  OtherScores,
  PlayerAttributeLabels,
  ReportCanDefendKeys,
  ReportPositionLabels,
  ReportRoleLabels,
  ReportScoreDescriptions,
  ReportScoutingLocationLabels,
  ReportTeamFitLabels,
} from "../../reports-const";
import { reportResponseToFormData } from "../../report-utils";
import { AccountInfo } from "@azure/msal-browser";
import { ThreeStateToggle } from "./ThreeStateToggle/ThreeStateToggle";
import { FormMultiSelect } from "./FormMultiSelect";
import { FormSelect } from "./FormSelect";
import { FormCheckboxGroup } from "./FormCheckboxGroup";
import { FormSkillSelect } from "./FormSkillSelect";

const REPORT_DEBOUNCE_TIME = 5000;

const formSchema = z.object({
  id: z.number().optional(),
  playerId: z.number(),
  teamId: z.number(),
  opponentTeamId: z.number().optional(),
  statusUpdatedAt: z.string().optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  authorUsername: z.string().optional(),
  authorName: z.string().optional(),
  gameVendor: z.nativeEnum(ReportUpsertParams.gameVendor),
  gameVendorId: z.string(),
  status: z.nativeEnum(ReportUpsertParams.status),
  level: z.nativeEnum(ReportUpsertParams.level),
  type: z.nativeEnum(ReportResponseItem.type),
  scoutLocation: z.nativeEnum(ReportUpsertParams.scoutLocation),
  position: z.nativeEnum(ReportUpsertParams.position),
  role: z.nativeEnum(ReportUpsertParams.role),
  teamFit: z.nativeEnum(ReportUpsertParams.teamFit),
  teamFitNotes: z.string().nullable().optional(),
  offensiveNotes: z.string().nullable().optional(),
  defensiveNotes: z.string().nullable().optional(),
  otherNotes: z.string().nullable().optional(),
  apolloGrades: z.record(
    z.nativeEnum(ReportApolloGrade.scope),
    z.nativeEnum(ReportApolloGrade.value)
  ),
  playerAttributes: z.array(z.nativeEnum(ReportPlayerAttribute.key)).optional(),
  scores: z.record(z.string(), z.string().nullable()),
});

const unpublishedFormSchema = formSchema.partial();

export type GameReportFormSchema = z.infer<typeof unpublishedFormSchema>;
export type PublishedGameReportFormSchema = z.infer<typeof formSchema>;

interface GameReportFormProps {
  onSubmit: (
    data: GameReportFormSchema,
    publish?: boolean,
    autosave?: boolean
  ) => void;
  onPublish: (data: PublishedGameReportFormSchema) => void;
  game: ScoreboardGame;
  player: PlayerRow;
  report: ReportResponseItem | null;
  author: AccountInfo;
  homeTeam: TeamRow;
  awayTeam: TeamRow;
}

export function GameReportForm({
  onSubmit,
  onPublish,
  report,
  player,
  author,
  game,
  homeTeam,
  awayTeam,
}: GameReportFormProps) {
  const form = useForm<GameReportFormSchema>({
    defaultValues: reportResponseToFormData(
      report,
      player,
      author,
      game,
      homeTeam,
      awayTeam
    ),
  });

  const { watch, handleSubmit, formState } = form;

  async function handlePublish() {
    const values = form.getValues();

    const res = await formSchema.safeParseAsync(values);

    if (!res.success) {
      res.error.issues.forEach((issue) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        form.setError(issue.path.join(".") as any, {
          type: "custom",
          message: issue.message,
        });
      });

      toast.error(`Unable to publish report. All scores are required.`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        theme: "colored",
      });
    } else {
      onPublish(values as PublishedGameReportFormSchema);
    }
  }

  // debounce changes by 2 seconds
  const debounced = useDebouncedCallback(() => {
    if (Object.keys(formState.dirtyFields).length > 0) {
      handleSubmit((values) => onSubmit(values, false, true))();
    }
  }, REPORT_DEBOUNCE_TIME);

  // watch for changes and call debounced callback on changes
  useEffect(() => {
    const sub = watch(() => {
      debounced();
    });

    return () => {
      debounced.flush();
      sub.unsubscribe();
    };
  }, [watch, debounced]);

  return (
    <Form {...form} key={player.id}>
      <form>
        <Flex direction="down" gap="lg">
          {/* Suns Fit */}
          {player.level === "PRO" ? (
            <Flex direction="down" gap="md">
              <FormMultiSelect
                name="teamFit"
                options={Object.values(ReportUpsertParams.teamFit).map(
                  (value) => ({
                    value,
                    label: ReportTeamFitLabels[value],
                  })
                )}
              />
              <FormField
                name="teamFitNotes"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </Flex>
          ) : null}

          {/* Position/Role */}
          <Flex direction="right" gap="lg">
            <FormSelect
              title="Position"
              name="position"
              placeholder="Select an option"
              options={Object.values(ReportUpsertParams.position).map(
                (value) => ({
                  value,
                  label: ReportPositionLabels[value],
                })
              )}
              labelClassName="text-lg font-bold"
            />

            <FormSelect
              title="Role"
              name="role"
              placeholder="Select an option"
              options={Object.values(ReportUpsertParams.role).map((value) => ({
                value,
                label: ReportRoleLabels[value],
              }))}
              labelClassName="text-lg font-bold"
            />
          </Flex>

          <Flex direction="down" gap="sm">
            <Text size="lg" heading asChild>
              <FormLabel>Apollo Grade</FormLabel>
            </Text>

            {/* Apollo Grade */}
            {player.level === "PRO" ? (
              <Flex direction="right" gap="lg">
                <FormSelect
                  title={
                    report?.type === ReportResponseItem.type.PHASE
                      ? "Today"
                      : null
                  }
                  name={`apolloGrades.${ReportApolloGrade.scope.CURRENT}`}
                  placeholder="Select an option"
                  options={Object.values(ReportApolloGrade.value).map(
                    (value) => ({
                      value,
                      label: ApolloGradeValueLabels[value],
                    })
                  )}
                />

                {report?.type === ReportResponseItem.type.PHASE ? (
                  <FormSelect
                    title="Remaining capacity"
                    name={`apolloGrades.${ReportApolloGrade.scope.REMAINING_CAPACITY}`}
                    placeholder="Select an option"
                    options={Object.values(ReportApolloGrade.value).map(
                      (value) => ({
                        value,
                        label: ApolloGradeValueLabels[value],
                      })
                    )}
                  />
                ) : null}
              </Flex>
            ) : (
              <Flex direction="right" gap="lg">
                <FormSelect
                  title="Ceiling"
                  name={`apolloGrades.${ReportApolloGrade.scope.CEILING}`}
                  placeholder="Select an option"
                  options={Object.values(ReportApolloGrade.value).map(
                    (value) => ({
                      value,
                      label: ApolloGradeValueLabels[value],
                    })
                  )}
                />
                <FormSelect
                  title="Bullseye"
                  name={`apolloGrades.${ReportApolloGrade.scope.BULLSEYE}`}
                  placeholder="Select an option"
                  options={Object.values(ReportApolloGrade.value).map(
                    (value) => ({
                      value,
                      label: ApolloGradeValueLabels[value],
                    })
                  )}
                />
                <FormSelect
                  title="Basement"
                  name={`apolloGrades.${ReportApolloGrade.scope.BASEMENT}`}
                  placeholder="Select an option"
                  options={Object.values(ReportApolloGrade.value).map(
                    (value) => ({
                      value,
                      label: ApolloGradeValueLabels[value],
                    })
                  )}
                />
              </Flex>
            )}
          </Flex>

          <Flex direction="down" gap="sm">
            <Text size="lg" heading asChild>
              <FormLabel>Player 15 Eval</FormLabel>
            </Text>
            <FormCheckboxGroup
              name="playerAttributes"
              options={Object.values(ReportPlayerAttribute.key).map(
                (value) => ({
                  value,
                  label: PlayerAttributeLabels[value],
                })
              )}
              multiselect={true}
            />
          </Flex>

          <Flex direction="down" gap="md">
            <Text size="lg" heading asChild>
              <FormLabel>Offense</FormLabel>
            </Text>
            <Text size="md" muted heading asChild>
              <FormLabel>Skill</FormLabel>
            </Text>
            <Flex direction="right" gap="lg" wrap>
              {OffenseSkillScores.map((score) => (
                <FormSkillSelect
                  key={score}
                  name={`scores.${score}`}
                  label={ReportScoreDescriptions[score].label}
                  tooltip={ReportScoreDescriptions[score].description}
                />
              ))}
            </Flex>

            <Text size="md" className="mt-4" muted heading asChild>
              <FormLabel>Shooting</FormLabel>
            </Text>
            <Flex direction="right" gap="lg" wrap>
              {OffenseShootingScores.map((score) => (
                <FormSkillSelect
                  key={score}
                  name={`scores.${score}`}
                  label={ReportScoreDescriptions[score].label}
                  tooltip={ReportScoreDescriptions[score].description}
                />
              ))}
            </Flex>
          </Flex>

          <FormField
            name="offensiveNotes"
            render={({ field }) => (
              <FormItem>
                <Text size="md" muted heading asChild>
                  <FormLabel>Offensive notes</FormLabel>
                </Text>
                <FormControl>
                  <TextEditor
                    className="h-44"
                    {...field}
                    defaultValue={report?.offensiveNotes}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <Flex direction="down" gap="md">
            <Text size="lg" heading asChild>
              <FormLabel>Defense</FormLabel>
            </Text>

            <Text size="md" muted heading asChild>
              <FormLabel>Can defend</FormLabel>
            </Text>

            <Flex direction="right" gap="sm">
              {ReportCanDefendKeys.map((score) => (
                <FormField
                  key={score}
                  name={`scores.${score}`}
                  render={({ field }) => (
                    <FormItem key={score}>
                      <FormControl>
                        <ThreeStateToggle
                          {...field}
                          value={field.value ? Number(field.value) : 0}
                          onChange={(value) => {
                            field.onChange(`${value}`);
                          }}
                        >
                          {ReportScoreDescriptions[score].label}
                        </ThreeStateToggle>
                      </FormControl>
                    </FormItem>
                  )}
                />
              ))}
            </Flex>

            <Text size="md" muted heading asChild>
              <FormLabel>On-ball</FormLabel>
            </Text>
            <Flex direction="right" gap="lg" wrap>
              {DefenseOnBallScores.map((score) => (
                <FormSkillSelect
                  key={score}
                  name={`scores.${score}`}
                  label={ReportScoreDescriptions[score].label}
                  tooltip={ReportScoreDescriptions[score].description}
                />
              ))}
            </Flex>

            <Text size="md" className="mt-4" muted heading asChild>
              <FormLabel>Off-ball</FormLabel>
            </Text>
            <Flex direction="right" gap="lg" wrap>
              {DefenseOffBallScores.map((score) => (
                <FormSkillSelect
                  key={score}
                  name={`scores.${score}`}
                  label={ReportScoreDescriptions[score].label}
                  tooltip={ReportScoreDescriptions[score].description}
                />
              ))}
            </Flex>

            <FormField
              name="defensiveNotes"
              render={({ field }) => (
                <FormItem>
                  <Text size="md" muted heading asChild>
                    <FormLabel>Defensive notes</FormLabel>
                  </Text>
                  <FormControl>
                    <TextEditor
                      className="h-44"
                      {...field}
                      defaultValue={report?.defensiveNotes}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </Flex>

          <Flex direction="down" gap="md">
            <Text size="md" heading asChild>
              <FormLabel>Other</FormLabel>
            </Text>
            <Flex direction="right" gap="lg" wrap>
              {OtherScores.map((score) => (
                <FormSkillSelect
                  key={score}
                  name={`scores.${score}`}
                  label={ReportScoreDescriptions[score].label}
                  tooltip={ReportScoreDescriptions[score].description}
                />
              ))}
            </Flex>

            <FormField
              name="otherNotes"
              render={({ field }) => (
                <FormItem>
                  <Text size="md" muted heading asChild>
                    <FormLabel>Other notes</FormLabel>
                  </Text>
                  <FormControl>
                    <TextEditor
                      className="h-44"
                      {...field}
                      defaultValue={report?.otherNotes}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </Flex>

          <Flex direction="down" gap="sm">
            <Text size="lg" heading asChild>
              <FormLabel>Scouting location</FormLabel>
            </Text>
            <FormCheckboxGroup
              name="scoutLocation"
              options={Object.values(ReportUpsertParams.scoutLocation).map(
                (value) => ({
                  value,
                  label: ReportScoutingLocationLabels[value],
                })
              )}
              multiselect={false}
            />
          </Flex>
        </Flex>

        <Flex direction="right" gap="md" className="mt-6">
          <Button
            type="button"
            variant="muted"
            onClick={handleSubmit(handlePublish)}
          >
            Publish
          </Button>
          <Button
            type="button"
            onClick={() => {
              handleSubmit((values) => {
                onSubmit(values, false, false);
              })();
            }}
          >
            Save
          </Button>
        </Flex>
      </form>
    </Form>
  );
}
