import { useAccount } from "@/shared/hooks/useAccount";
import { Card, Flex, SkeletonText, Text } from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { useParams } from "react-router-dom";
import { editReportLoader } from "./loaders/edit-report-loader";
import { ChevronRight } from "@suns/design-system/icons";
import { GameHero, GameHeroSkeleton } from "@/components/GameHero/GameHero";
import { PlayerHeader, PlayerHeaderSkeleton } from "@/components";
import { reportFormDataToUpsertParams } from "../report-utils";
import { apolloApi } from "@/shared/api";
import { toast } from "react-toastify";
import {
  GameReportForm,
  GameReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { useState } from "react";
import { ReportUpsertParams } from "@suns/api/generated-client/apollo";

function toastSuccess(message: string) {
  toast.success(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    theme: "colored",
  });
}

function toastError(message: string) {
  toast.error(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    theme: "colored",
  });
}

export function ReportEdit() {
  const { reportId } = useParams();
  const account = useAccount();
  const [isSaving, setIsSaving] = useState(false);

  const { loading, response } = useAsync(editReportLoader, {
    reportId: reportId || "",
  });

  async function handleSubmit(report: GameReportFormSchema) {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    try {
      const upsertParams = reportFormDataToUpsertParams(report);

      await apolloApi.saveReport(upsertParams);

      toastSuccess("Report Saved");
    } catch (e) {
      toastError("Unable to save report. Please try again.");
    } finally {
      setIsSaving(false);
    }
  }

  async function handlePublish(report: GameReportFormSchema) {
    try {
      const upsertParams: ReportUpsertParams = {
        id: report.id,
        playerId: report.playerId,
        gameVendorId: report.gameVendorId,
        status: ReportUpsertParams.status.PUBLISHED,
      };

      await apolloApi.saveReport(upsertParams);

      toastSuccess("Report Published");
    } catch (e) {
      toastError("Error Publishing Report");
    }
  }

  if (loading) {
    return <ReportEditLoading />;
  }

  if (!response) {
    throw new Error("Error loading report");
  }

  const { report, game, homeTeam, awayTeam } = response;

  if (!report || !game || !homeTeam || !awayTeam || !account.info) {
    throw new Error("Error loading report data");
  }

  const playerTeam =
    report.player.currentTeamId === homeTeam.id ? homeTeam : awayTeam;

  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Edit Report</Text>
      </Flex>
      <Card className="mb-4 overflow-hidden">
        <GameHero
          gameId={game.gameId ?? ""}
          gameStatus={game.gameStatus || 1}
          gameTimeUtc={game.gameTimeUTC!}
          gameClock={game.gameClock!}
          period={game.period!}
          homeTeam={{
            id: game.homeTeam!.teamId!,
            tricode: game.homeTeam!.teamTricode!,
            score: game.homeTeam!.score!,
          }}
          awayTeam={{
            id: game.awayTeam!.teamId!,
            tricode: game.awayTeam!.teamTricode!,
            score: game.awayTeam!.score!,
          }}
        />
      </Card>
      <Card>
        <Flex direction="down" align="center" className="w-full">
          <Flex direction="down" className="max-w-4xl" gap="lg">
            <PlayerHeader
              {...report.player}
              teamNbaId={playerTeam.nbaId}
              teamName={playerTeam.name}
            />
            <GameReportForm
              report={report}
              onPublish={handlePublish}
              onSubmit={handleSubmit}
              player={report.player}
              game={game}
              homeTeam={homeTeam}
              awayTeam={awayTeam}
              author={account.info}
            />
          </Flex>
        </Flex>
      </Card>
    </>
  );
}

function ReportEditLoading() {
  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Edit Report</Text>
      </Flex>
      <Card className="mb-4">
        <GameHeroSkeleton />
      </Card>
      <Card className="grid grid-cols-4 space-x-8">
        <Flex direction="down">
          <SkeletonText rows={5} />
        </Flex>
        <PlayerHeaderSkeleton />
      </Card>
    </>
  );
}
