import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Flex,
  Select,
  SelectOption,
  SkeletonText,
  Tabs,
  Text,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";
import {
  PlayerRow,
  ReportResponseItem,
  ReportUpsertParams,
  TeamRow,
} from "@suns/api/generated-client/apollo";
import {
  PlayerHeader,
  PlayerHeaderSkeleton,
  GameHero,
  GameHeroSkeleton,
} from "@/components";
import { useAccount, useAsync } from "@/shared/hooks";
import { apolloApi } from "@/shared/api";
import { reportLoader } from "./loaders/report-loader";
import { reportFormDataToUpsertParams } from "../report-utils";
import {
  GameReportForm,
  GameReportFormSchema,
} from "../components/ReportForm/ReportForm";
import { notify } from "@/components/bugsnag";
import { PlayerReportItem } from "./components/PlayerReportItem";
import { PlayerList } from "./components/PlayerList";

type TeamTabValues = "home" | "away";

function toastSuccess(message: string) {
  toast.success(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    theme: "colored",
  });
}

function toastInfo(message: string) {
  toast.info(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    theme: "colored",
  });
}

function toastError(message: string) {
  toast.error(message, {
    toastId: message,
    position: "bottom-right",
    autoClose: 2000,
    hideProgressBar: true,
    theme: "colored",
  });
}

const sortByMetadataAndName = (player1: PlayerRow, player2: PlayerRow) => {
  if (player1.metadata?.target && !player2.metadata?.target) return -1;
  if (!player1.metadata?.target && player2.metadata?.target) return 1;

  const lastNameComparison = player1.lastName.localeCompare(player2.lastName);
  if (lastNameComparison !== 0) {
    return lastNameComparison;
  }
  return player1.firstName.localeCompare(player2.firstName);
};

export function CreateGameReport() {
  const { gameId } = useParams();
  const account = useAccount();

  const [isSaving, setIsSaving] = useState(false);

  const [reports, setReports] = useState<Record<string, ReportResponseItem>>(
    {}
  );
  const [activeTab, setActiveTab] = useState<TeamTabValues>("home");
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerRow | null>(null);

  const {
    loading,
    response,
    error: responseError,
  } = useAsync(reportLoader, {
    gameVendorId: gameId ?? "",
    authorUsername: account.info?.username ?? "",
  });

  useEffect(() => {
    if (response && response.reports) {
      setReports(response.reports);

      const firstPlayer =
        response.homeTeam?.currentPlayers.sort(sortByMetadataAndName)[0] ??
        null;
      setSelectedPlayer(firstPlayer);
    }
  }, [response]);

  if (loading || !selectedPlayer) {
    return <CreateGameReportLoading />;
  }

  if (!response || !gameId || !account.info) {
    notify(new Error("Error loading the game.", { cause: responseError }));
    throw Error("There was an error loading the game.");
  }

  const { game, homeTeam, awayTeam } = response;

  if (!homeTeam || !awayTeam || !game) {
    notify(new Error("Error loading the rosters.", { cause: responseError }));
    throw Error("There was an error loading the rosters.");
  }

  function handleTabChange(newTab: string) {
    if (newTab !== activeTab) {
      setActiveTab(newTab as TeamTabValues);
      if (newTab === "home") {
        handlePlayerSelected(homeTeam?.currentPlayers[0] ?? null);
      } else {
        handlePlayerSelected(awayTeam?.currentPlayers[0] ?? null);
      }
    }
  }

  function handlePlayerSelected(player: PlayerRow | null) {
    if (player) {
      setSelectedPlayer(player);
    }
  }

  async function handleSubmit(report: GameReportFormSchema) {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    try {
      const upsertParams = reportFormDataToUpsertParams(report);

      const existingReport = report.playerId
        ? reports[report.playerId]
        : undefined;
      if (existingReport != undefined) {
        upsertParams.id = existingReport.id;
      }

      const res = await apolloApi.saveReport(upsertParams);
      setReports({
        ...reports,
        [res.report.playerId]: res.report,
      });

      toastInfo("Report Saved");
    } catch (e) {
      notify(new Error("Error saving report.", { cause: e }));
      toastError("Unable to save report. Please try again.");
    } finally {
      setIsSaving(false);
    }
  }

  async function handlePublish(report: GameReportFormSchema) {
    try {
      const upsertParams: ReportUpsertParams = {
        playerId: report.playerId,
        gameVendorId: report.gameVendorId,
        status: ReportUpsertParams.status.PUBLISHED,
      };

      const existingReport = report.playerId
        ? reports[report.playerId]
        : undefined;
      if (existingReport != undefined) {
        upsertParams.id = existingReport.id;
      }

      const res = await apolloApi.saveReport(upsertParams);
      setReports({
        ...reports,
        [res.report.playerId]: res.report,
      });

      toastSuccess("Report Published");
    } catch (e) {
      toastError("Error Publishing Report");
      notify(new Error("Error publishing report", { cause: e }));
    }
  }

  const activeReport = reports[selectedPlayer.id];
  const activeTeam = activeTab === "home" ? homeTeam : awayTeam;

  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Create Report</Text>
      </Flex>
      <Card className="mb-4 overflow-hidden">
        <GameHero
          gameId={gameId}
          gameStatus={game.gameStatus || 1}
          gameTimeUtc={game.gameTimeUTC!}
          gameClock={game.gameClock!}
          period={game.period!}
          homeTeam={{
            id: game.homeTeam!.teamId!,
            tricode: game.homeTeam!.teamTricode!,
            score: game.homeTeam!.score!,
          }}
          awayTeam={{
            id: game.awayTeam!.teamId!,
            tricode: game.awayTeam!.teamTricode!,
            score: game.awayTeam!.score!,
          }}
        />
      </Card>
      <Card className="mb-4 md:hidden">
        <TeamTabs
          handleTabChange={handleTabChange}
          handlePlayerSelected={handlePlayerSelected}
          homeTeam={homeTeam}
          awayTeam={awayTeam}
          activePlayer={selectedPlayer}
          reports={reports}
        />
      </Card>
      <Card className="md:grid md:grid-cols-5 md:space-x-8">
        <Flex className="hidden md:flex">
          <TeamTabs
            handleTabChange={handleTabChange}
            handlePlayerSelected={handlePlayerSelected}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            activePlayer={selectedPlayer}
            reports={reports}
          />
        </Flex>
        <Flex className="col-span-4" direction="down" gap="lg">
          <PlayerHeader
            {...selectedPlayer}
            teamNbaId={activeTeam.nbaId}
            teamName={activeTeam.name}
          />
          <GameReportForm
            key={selectedPlayer.id}
            onSubmit={handleSubmit}
            onPublish={handlePublish}
            player={selectedPlayer}
            game={game}
            report={activeReport}
            author={account.info}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
          />
        </Flex>
      </Card>
    </>
  );
}

interface TeamTabsProps {
  handleTabChange: (value: string) => void;
  homeTeam: TeamRow;
  awayTeam: TeamRow;
  activePlayer: PlayerRow;
  reports: Record<string, ReportResponseItem>;
  handlePlayerSelected: (player: PlayerRow) => void;
}

function TeamTabs({
  handleTabChange,
  homeTeam,
  awayTeam,
  activePlayer,
  reports,
  handlePlayerSelected,
}: TeamTabsProps) {
  const sortedHomePlayers = homeTeam.currentPlayers.sort(sortByMetadataAndName);
  const sortedAwayPlayers = awayTeam.currentPlayers.sort(sortByMetadataAndName);

  return (
    <Tabs
      defaultValue="home"
      className="w-full"
      onValueChange={handleTabChange}
    >
      <TabsList>
        <TabsTrigger value="home">
          <span>{homeTeam.name}</span>
        </TabsTrigger>
        <TabsTrigger value="away">
          <span>{awayTeam.name}</span>
        </TabsTrigger>
      </TabsList>
      <TabsContent value="home">
        <PlayerList
          players={sortedHomePlayers}
          activePlayer={activePlayer}
          reports={reports}
          onPlayerClicked={handlePlayerSelected}
          className="hidden md:flex"
        />
        <Select
          className="w-full md:hidden"
          value={`${activePlayer.id}`}
          onValueChange={(value) => {
            const player = homeTeam.currentPlayers.find(
              (player) => `${player.id}` === value
            );
            if (player) {
              handlePlayerSelected(player);
            }
          }}
        >
          {sortedHomePlayers.map((player) => (
            <SelectOption key={player.id} value={`${player.id}`}>
              <PlayerReportItem
                player={player}
                reports={reports}
                activePlayer={activePlayer}
              />
            </SelectOption>
          ))}
        </Select>
      </TabsContent>
      <TabsContent value="away">
        <PlayerList
          players={sortedAwayPlayers}
          activePlayer={activePlayer}
          reports={reports}
          onPlayerClicked={handlePlayerSelected}
          className="hidden md:flex"
        />
        <Select
          className="w-full md:hidden"
          value={`${activePlayer.id}`}
          onValueChange={(value) => {
            const player = awayTeam.currentPlayers.find(
              (player) => `${player.id}` === value
            );
            if (player) {
              handlePlayerSelected(player);
            }
          }}
        >
          {sortedAwayPlayers.map((player) => (
            <SelectOption key={player.id} value={`${player.id}`}>
              <PlayerReportItem
                player={player}
                reports={reports}
                activePlayer={activePlayer}
              />
            </SelectOption>
          ))}
        </Select>
      </TabsContent>
    </Tabs>
  );
}

function CreateGameReportLoading() {
  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs">Reports</Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs">Create Report</Text>
      </Flex>
      <Card className="mb-4">
        <GameHeroSkeleton />
      </Card>
      <Card className="grid grid-cols-4 space-x-8">
        <Flex direction="down">
          <SkeletonText rows={5} />
        </Flex>
        <PlayerHeaderSkeleton />
      </Card>
    </>
  );
}
