import { useAccount } from "@/shared/hooks/useAccount";
import {
  Card,
  Flex,
  SkeletonText,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Text,
} from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { myReportsLoader } from "./loaders/my-reports-loader";
import ReportListItem from "./components/ReportListItem";
import { Separator } from "@suns/design-system/src/components/Separator/Separator";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { apolloApi } from "@/shared/api";

export function ReportsListings() {
  const account = useAccount();
  const { loading, response, refresh } = useAsync(myReportsLoader, {
    authorUsername: account.info?.username,
  });

  async function handleUnpublish(report: ReportResponseItem) {
    await apolloApi.saveReport({
      id: report.id,
      status: ReportResponseItem.status.UNPUBLISHED,
    });

    refresh();
  }

  if (loading) {
    return <ReportsListingsLoading />;
  }

  if (!response) {
    throw new Error("Unable to load reports.");
  }

  return (
    <Card header={<Text className="text-3xl font-bold">My Reports</Text>}>
      <Tabs defaultValue="published">
        <TabsList className="mb-6">
          <TabsTrigger value="published">
            <Text>Published</Text>
          </TabsTrigger>
          <TabsTrigger value="unpublished">
            <Text>Unpublished</Text>
          </TabsTrigger>
        </TabsList>
        <TabsContent value="published">
          {response.published.length > 0 ? (
            response.published.map((report, i) => (
              <Flex direction="down" key={report.id}>
                <ReportListItem report={report} onUnpublish={handleUnpublish} />
                {i !== response.published.length - 1 ? <Separator /> : null}
              </Flex>
            ))
          ) : (
            <Text>You don't have any published reports.</Text>
          )}
        </TabsContent>
        <TabsContent value="unpublished">
          {response.unpublished.length > 0 ? (
            response.unpublished.map((report, i) => (
              <Flex direction="down" key={report.id}>
                <ReportListItem report={report} onUnpublish={handleUnpublish} />
                {i !== response.unpublished.length - 1 ? <Separator /> : null}
              </Flex>
            ))
          ) : (
            <Text>You don't have any unpublished reports.</Text>
          )}
        </TabsContent>
      </Tabs>
    </Card>
  );
}

function ReportsListingsLoading() {
  return (
    <Card
      header={
        <Text size="3xl" heading>
          My Reports
        </Text>
      }
    >
      <Flex direction="down" gap="lg">
        <SkeletonText />
        <SkeletonText />
      </Flex>
    </Card>
  );
}
