import { useEffect, useState } from "react";
import { logout } from "@/shared/auth";
import { Link, NavLink } from "react-router-dom";
import logoMain from "@/assets/img/light-logo.svg";
import { URL } from "@/shared/utils/route-constant";
import {
  Accordion,
  Button,
  Card,
  Flex,
  Grid,
  Text,
  cn,
} from "@suns/design-system";
import { LibraryBig, LogOut, Menu, X } from "@suns/design-system/icons";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@suns/design-system/src/components/Accordion/Accordion";
import { useAccount } from "@/shared/hooks";
import { NAVIGATION, NavigationItem } from "@/shared/utils/navigation";
import { AccountWithRoles } from "@/shared/auth/account";

export default function SideBar() {
  const account = useAccount();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isMenuOpen]);

  return (
    <>
      <Header toggleMenu={toggleMenu} />
      {isMenuOpen && (
        <div className="fixed inset-0 z-10 bg-black bg-opacity-50 lg:hidden">
          {/* This overlay div will cover the entire screen in mobile view when the menu is open */}
        </div>
      )}
      <Card
        className={cn(
          `fixed right-0 z-20 h-full w-52 transform rounded-none border-0 bg-black
          transition-transform lg:left-0 lg:block`,
          isMenuOpen ? "translate-x-0" : "translate-x-full lg:translate-x-0"
        )}
      >
        <Flex direction="down" justify="between" className="h-full">
          <div>
            <Flex justify="end" className="lg:hidden ">
              <X
                onClick={toggleMenu}
                color="white"
                className="mb-4 size-6 stroke-2"
              />
            </Flex>
            <Flex
              direction="down"
              align="center"
              className=" my-4 xs:max-lg:hidden"
            >
              <NavLink to={"/"}>
                <img src={logoMain} />
              </NavLink>
            </Flex>

            {/* Render the navigation items */}
            {NAVIGATION.map((navItem) => {
              const hasPermission =
                !navItem.requiredRoles ||
                navItem.requiredRoles.some(
                  (role) => account[role as keyof typeof account] === true
                );

              if (!hasPermission) {
                return null;
              }

              const isActivePage =
                navItem.path != "/" &&
                location.pathname.startsWith(navItem.path);

              // navigation items with children should render as an accordian
              if (navItem.children) {
                return (
                  <AccordianNavigation
                    key={navItem.path}
                    isActivePage={isActivePage}
                    navigationItem={navItem}
                    account={account}
                    setIsMenuOpen={setIsMenuOpen}
                  />
                );
              } else {
                return (
                  <SingleItemNavigation
                    key={navItem.path}
                    navigationItem={navItem}
                  />
                );
              }
            })}
          </div>

          {/* Render Glossary and Logout buttons */}
          <NavigationControls setIsMenuOpen={setIsMenuOpen} />
        </Flex>
      </Card>
    </>
  );
}

function Header({ toggleMenu }: { toggleMenu: () => void }) {
  return (
    <Card className="fixed z-10 h-16 w-full rounded-none border-0 bg-black lg:hidden">
      <Flex justify="between" align="center">
        <NavLink to={URL.TEAM.path}>
          <Flex direction="right" align="center">
            <img className="h-8 pr-3" src={logoMain} />
            <Text heading className="text-white">
              Phoenix Suns
            </Text>
          </Flex>
        </NavLink>
        <Menu onClick={toggleMenu} className="size-8 stroke-2 text-white" />
      </Flex>
    </Card>
  );
}

interface NavLinkProps {
  to: string;
  children: React.ReactNode;
  end?: boolean;
  className?: string;
  setIsMenuOpen: (isOpen: boolean) => void;
}

const CustomNavLink = ({ to, children, end, setIsMenuOpen }: NavLinkProps) => {
  return (
    <NavLink
      className={({ isActive, isPending }) =>
        cn(
          "ml-10 text-sm text-gray-200 hover:text-primary",
          isActive && "font-semibold text-primary",
          isPending && "text-primary"
        )
      }
      to={to}
      end={end}
      onClick={() => {
        if (window.innerWidth < 1024) {
          setIsMenuOpen(false);
        }
      }}
    >
      {children}
    </NavLink>
  );
};

function AccordianNavigation({
  navigationItem,
  setIsMenuOpen,
  account,
  isActivePage = false,
}: {
  navigationItem: NavigationItem;
  setIsMenuOpen: (isOpen: boolean) => void;
  account: AccountWithRoles;
  isActivePage: boolean;
}) {
  return (
    <Accordion type="multiple" defaultValue={isActivePage ? ["item-1"] : []}>
      <AccordionItem value="item-1">
        <AccordionTrigger className="group">
          <Flex align="center">
            {navigationItem.Icon ? <navigationItem.Icon size={18} /> : null}
            <Text size="sm" className="ml-2">
              {navigationItem.title}
            </Text>
          </Flex>
        </AccordionTrigger>

        <AccordionContent>
          <Grid gap="md" className="mt-1">
            {navigationItem.children
              ? navigationItem.children.map((childNav) => {
                  const hasPermission =
                    !childNav.requiredRoles ||
                    childNav.requiredRoles.some(
                      (role) => account[role as keyof typeof account] === true
                    );

                  if (!hasPermission) {
                    return null;
                  }
                  return (
                    <CustomNavLink
                      key={childNav.path}
                      to={`${childNav.path}`}
                      end
                      setIsMenuOpen={setIsMenuOpen}
                    >
                      {childNav.title}
                    </CustomNavLink>
                  );
                })
              : null}
          </Grid>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}

function SingleItemNavigation({
  navigationItem,
}: {
  navigationItem: NavigationItem;
}) {
  return (
    <Link to={navigationItem.path}>
      <Button
        variant="ghost"
        className="h-12 w-full text-sm font-medium text-white hover:bg-purple-800 hover:font-bold
          hover:text-white"
      >
        <Flex align="center" justify="start" className="w-full">
          {navigationItem.Icon ? (
            <navigationItem.Icon size={18} color="white" className="mr-2" />
          ) : null}
          {navigationItem.title}
        </Flex>
      </Button>
    </Link>
  );
}

function NavigationControls({
  setIsMenuOpen,
}: {
  setIsMenuOpen: (isOpen: boolean) => void;
}) {
  return (
    <Flex justify="end" direction="down" className="pl-6">
      <NavLink
        to={URL.GLOSSARY.path}
        className={({ isActive }) =>
          isActive
            ? "text-medium text-xs font-semibold text-primary"
            : "text-xs font-medium text-gray-400 "
        }
        end
        onClick={() => {
          if (window.innerWidth < 1024) {
            setIsMenuOpen(false);
          }
        }}
      >
        <Flex align="center" className="mb-4 cursor-pointer">
          <LibraryBig
            className={cn(
              "size-5",
              location.pathname === "/glossary" && "text-primary"
            )}
          />
          <Text size="sm" className="pl-2 ">
            Glossary
          </Text>
        </Flex>
      </NavLink>
      <NavLink to={URL.AUTH.children.LOGIN.path}>
        <Flex onClick={logout} align="center" className="cursor-pointer">
          <LogOut className="size-5 text-gray-200" />
          <Text size="sm" className="pl-2 font-medium text-gray-200">
            Logout
          </Text>
        </Flex>
      </NavLink>
    </Flex>
  );
}
