import { Card, Flex, SkeletonText, Text } from "@suns/design-system";
import { useAsync } from "@/shared/hooks/useAsync";
import { allReportsLoader } from "./loaders/all-reports-loader";
import ReportListItem from "./components/ReportListItem";
import { Separator } from "@suns/design-system/src/components/Separator/Separator";
import PaginationControl from "@/components/PaginationControl/PaginationControl";
import { useSearchParams } from "react-router-dom";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";
import { apolloApi } from "@/shared/api";

const PAGE_SIZE = 20;

export function AllReportsListings() {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") ?? "1";

  const { loading, response, refresh } = useAsync(allReportsLoader, {
    offset: (parseInt(page, 10) - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
  });

  async function handleUnpublish(report: ReportResponseItem) {
    await apolloApi.saveReport({
      id: report.id,
      status: ReportResponseItem.status.UNPUBLISHED,
    });

    refresh();
  }

  if (loading) {
    return <ReportsListingsLoading />;
  }

  if (!response) {
    throw new Error("Unable to load reports.");
  }

  const currentPage = Math.floor(response.offset / response.limit);
  if (parseInt(page, 10) - 1 !== currentPage) {
    refresh();
    window.scrollTo({ top: 0, behavior: "instant" });
  }

  return (
    <Card header={<Text className="text-3xl font-bold">All Reports</Text>}>
      {response.reports.map((report) => (
        <Flex direction="down" key={report.id}>
          <ReportListItem report={report} onUnpublish={handleUnpublish} />
          <Separator />
        </Flex>
      ))}
      <PaginationControl
        {...response}
        onPageClicked={(page) => {
          setSearchParams({ page: `${page}` });
        }}
      />
    </Card>
  );
}

function ReportsListingsLoading() {
  return (
    <Card
      header={
        <Text size="3xl" heading>
          All Reports
        </Text>
      }
    >
      <Flex direction="down" gap="lg">
        <SkeletonText />
        <SkeletonText />
      </Flex>
    </Card>
  );
}
