import { PlayerHeadshot } from "@/components/PlayerHeadshot/PlayerHeadshot";
import { Flex, Skeleton, SkeletonText, Text } from "@suns/design-system";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { TeamLogo } from "@/components/TeamLogo/TeamLogo";

dayjs.extend(relativeTime);

interface PlayerHeaderProps {
  nbaId: number;
  firstName: string;
  lastName: string;
  height: string;
  weight: number;
  birthDate: string;
  wing: number;
  reach: number;
  teamNbaId: number;
  teamName: string;
}

interface StatCategoryProps {
  label: string;
  value: string;
}

function formatHeight(height: string): string {
  const parts = height.split("-");
  if (parts.length === 2) {
    return `${parts[0]}' ${parts[1]}`;
  } else {
    return height;
  }
}

function formatInchesToFeet(inches: number): string {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  return `${feet}' ${remainingInches}`;
}

function formatAge(birthDate: string): string {
  return dayjs().diff(dayjs(birthDate), "year", true).toFixed(1);
}

function StatCategory({ label, value }: StatCategoryProps) {
  return (
    <Flex direction="down">
      <Text size="xs">{label}</Text>
      <Text size="lg" heading>
        {value}
      </Text>
    </Flex>
  );
}

export function PlayerHeader({
  nbaId,
  firstName,
  lastName,
  height,
  weight,
  birthDate,
  wing,
  reach,
  teamNbaId,
  teamName,
}: PlayerHeaderProps) {
  return (
    <Flex gap="lg" className="content-center items-center">
      <Flex className="aspect-square">
        <PlayerHeadshot playerId={nbaId} size="lg" />
      </Flex>
      <Flex direction="down" className="justify-between">
        <Text size="xl" heading>
          {firstName} {lastName}
        </Text>
        <Flex
          direction="right"
          gap="xs"
          className="hidden items-center md:flex"
        >
          <TeamLogo teamId={teamNbaId} size="xxs" />
          <Text size="xs">{teamName}</Text>
        </Flex>

        <Flex direction="right" className="gap-x-md pt-2 md:gap-x-lg" wrap>
          <StatCategory label="Height" value={formatHeight(height)} />
          <StatCategory label="Weight" value={`${weight} lbs`} />
          <StatCategory label="Age" value={formatAge(birthDate)} />
          <StatCategory
            label="Wing"
            value={wing ? formatInchesToFeet(wing) : "NA"}
          />
          <StatCategory
            label="Reach"
            value={reach ? formatInchesToFeet(reach) : "NA"}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export function PlayerHeaderSkeleton() {
  return (
    <Flex gap="lg" className="content-center">
      {/* <PlayerHeadshot playerId={nbaId} size="lg" /> */}
      <Skeleton className="aspect-square h-24 w-auto rounded-full" />
      <Flex direction="down" gap="sm">
        {/* <SkeletonHeading /> */}
        <SkeletonText rows={1} className="h-6 w-64" />
        <SkeletonText rows={1} className="h-4 w-40" />

        <Flex direction="right" gap="md">
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
          <Skeleton className="h-10 w-16" />
        </Flex>
      </Flex>
    </Flex>
  );
}
