import { apolloApi, getGame } from "@/shared/api";

export async function editReportLoader({ reportId }: { reportId: string }) {
  const reportResponse = await apolloApi.getReport(parseInt(reportId, 10));

  const game = await getGame({ gameId: reportResponse.report.gameVendorId });
  let homeTeam = null;
  let awayTeam = null;

  const homeTeamRes = await apolloApi.getTeams({
    nbaId: `${game.homeTeam?.teamId}`,
  });
  if (homeTeamRes && homeTeamRes.count > 0) {
    homeTeam = homeTeamRes.teams[0];
  }

  const awayTeamRes = await apolloApi.getTeams({
    nbaId: `${game.awayTeam?.teamId}`,
  });
  if (awayTeamRes && awayTeamRes.count > 0) {
    awayTeam = awayTeamRes.teams[0];
  }

  return { report: reportResponse.report, game, homeTeam, awayTeam };
}
