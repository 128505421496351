import { TeamService } from "@/swagger";
import { useEffect, useState } from "react";
import { TeamRow } from "@/swagger/models/TeamRow";

const League = {
  NBA: 1,
};

export function useGetTeams({ league }: { league: string }) {
  const [teamList, setTeamList] = useState<TeamRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  let leagueNumber: number;
  if (league.toLowerCase() === "nba") {
    leagueNumber = League.NBA;
  } else {
    leagueNumber = parseInt(league, 10);
  }

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const data = await TeamService.getTeams();
        const filteredData = data.teams.filter(
          (team: TeamRow) => team.domesticLeagueId === leagueNumber
        );
        const sortedTeams = filteredData.sort((a: TeamRow, b: TeamRow) =>
          a.full_name.localeCompare(b.full_name)
        );
        setTeamList(sortedTeams);
      } catch (err) {
        setError(err as string);
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { teamList, loading, error };
}
