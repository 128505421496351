/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type IntelUpsertParams = {
    id?: number;
    playerId?: number;
    teamId?: number;
    level?: IntelUpsertParams.level;
    status?: IntelUpsertParams.status;
    notes?: string;
    source?: string;
    intelTags?: Array<string>;
    trustworthiness?: number;
    authorUsername?: string;
    authorName?: string;
};
export namespace IntelUpsertParams {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
}

