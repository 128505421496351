import { SunsApi } from "@suns/api";
import { getAuthToken, logout } from "./auth";
import { AxiosError } from "axios";

export const { getGame, getTeam, getTeams, getSchedule, apolloApi } =
  new SunsApi({
    apiKeys: {
      // TODO: Hide these behind our Apollo API.
      // These api endpoints don't offer sensitive data so it's not critical.
      querytool: import.meta.env.VITE_NBA_API_QUERY_TOOL_KEY,
      scores: import.meta.env.VITE_NBA_API_SCORES_KEY,
      stats: import.meta.env.VITE_NBA_API_STATS_KEY,
      schedule: import.meta.env.VITE_NBA_API_SCHEDULE_KEY,
    },
    apolloUrl: import.meta.env.VITE_API_URL,
    nbaUrl: `${import.meta.env.VITE_API_URL}/nba-api`,
    getToken: async () => {
      const token = await getAuthToken();
      if (!token) {
        logout();
        throw new Error("Token authentication failed.");
      }
      return token;
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 401) {
        logout();
      }
    },
  });
