/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReportApolloGradeUpsertParams } from './ReportApolloGradeUpsertParams';
import type { ReportPlayerAttributeUpsertParams } from './ReportPlayerAttributeUpsertParams';
import type { ReportScoreUpsertParams } from './ReportScoreUpsertParams';
export type ReportUpsertParams = {
    id?: number;
    playerId?: number;
    teamId?: number;
    opponentTeamId?: number;
    gameVendorId?: string;
    multiGameVendorIds?: Array<string>;
    gameVendor?: ReportUpsertParams.gameVendor;
    status?: ReportUpsertParams.status;
    level?: ReportUpsertParams.level;
    type?: string;
    scoutLocation?: ReportUpsertParams.scoutLocation;
    position?: ReportUpsertParams.position;
    role?: ReportUpsertParams.role;
    teamFit?: ReportUpsertParams.teamFit;
    teamFitNotes?: string;
    offensiveNotes?: string;
    defensiveNotes?: string;
    otherNotes?: string;
    authorUsername?: string;
    authorName?: string;
    phaseReportId?: number;
    apolloGrades?: Array<ReportApolloGradeUpsertParams>;
    playerAttributes?: Array<ReportPlayerAttributeUpsertParams>;
    scores?: Array<ReportScoreUpsertParams>;
};
export namespace ReportUpsertParams {
    export enum gameVendor {
        DRAFT_EXPRESS = 'DRAFT_EXPRESS',
        NBA = 'NBA',
        SECOND_SPECTRUM = 'SECOND_SPECTRUM',
    }
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
    export enum scoutLocation {
        ON_SITE = 'ON_SITE',
        VIDEO = 'VIDEO',
    }
    export enum position {
        POINT_GUARD = 'POINT_GUARD',
        GUARD = 'GUARD',
        WING = 'WING',
        FORWARD = 'FORWARD',
        BIG = 'BIG',
    }
    export enum role {
        PLAYMAKER = 'PLAYMAKER',
        SHOOTER = 'SHOOTER',
        PERIMETER_SCORER = 'PERIMETER_SCORER',
        SLASHER_DRIVER = 'SLASHER_DRIVER',
        INTERIOR_FINISHER = 'INTERIOR_FINISHER',
        PERIMETER_DEFENDER = 'PERIMETER_DEFENDER',
        INTERNAL_DEFENDER = 'INTERNAL_DEFENDER',
    }
    export enum teamFit {
        YES = 'YES',
        NO = 'NO',
        NA = 'NA',
    }
}

