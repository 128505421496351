/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type IntelRow = {
    id: number;
    playerId: number;
    teamId: number;
    level: IntelRow.level;
    status: IntelRow.status;
    statusUpdatedAt: string;
    notes: string;
    source: string;
    trustworthiness: number;
    trustworthinessMax: number;
    authorUsername: string;
    authorName: string;
    intelTags: Array<string>;
    tags: Array<string>;
    updatedAt: string;
    createdAt: string;
};
export namespace IntelRow {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
    export enum status {
        UNPUBLISHED = 'UNPUBLISHED',
        PUBLISHED = 'PUBLISHED',
    }
}

