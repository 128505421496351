/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TeamResponse } from '../models/TeamResponse';
import type { TeamsResponse } from '../models/TeamsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TeamService {
    /**
     * Get all teams
     * @param city
     * @param code
     * @param countryCode
     * @param domesticLeagueId
     * @param dxId
     * @param internationalLeagueId
     * @param level
     * @param fullName
     * @param name
     * @param nbaId
     * @param ssId
     * @param ids
     * @returns TeamsResponse Success.
     * @throws ApiError
     */
    public static getTeams(
        city?: string,
        code?: string,
        countryCode?: string,
        domesticLeagueId?: string,
        dxId?: string,
        internationalLeagueId?: number,
        level?: string,
        fullName?: string,
        name?: string,
        nbaId?: string,
        ssId?: string,
        ids?: Array<string>,
    ): CancelablePromise<TeamsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/teams',
            query: {
                'city': city,
                'code': code,
                'countryCode': countryCode,
                'domesticLeagueId': domesticLeagueId,
                'dxId': dxId,
                'internationalLeagueId': internationalLeagueId,
                'level': level,
                'full_name': fullName,
                'name': name,
                'nbaId': nbaId,
                'ssId': ssId,
                'ids': ids,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Get a team by ID
     * @returns TeamResponse Success.
     * @throws ApiError
     */
    public static getTeamById(): CancelablePromise<TeamResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/teams/{id}',
            errors: {
                400: `Bad Request`,
                401: `Unauthorize.`,
                403: `Forbidden`,
            },
        });
    }
}
