import { PlayerHeadshot } from "@/components/PlayerHeadshot/PlayerHeadshot";
import { PlayerRow } from "@/swagger";
import { Button, cn, Flex } from "@suns/design-system";

export default function PlayerCards({
  players,
  selectedPlayers,
  setSelectedPlayers,
}: {
  players: PlayerRow[];
  selectedPlayers: PlayerRow | null;
  setSelectedPlayers: (player: PlayerRow) => void;
}) {
  return (
    <>
      {players?.map((player: PlayerRow) => {
        return (
          <Button
            variant="outline"
            key={player.nbaId}
            className={cn(
              "justify-start py-8 text-sm hover:bg-gray-200",
              selectedPlayers?.nbaId === player.nbaId && " bg-gray-200"
            )}
            onClick={() => setSelectedPlayers(player as PlayerRow)}
          >
            <Flex gap="sm" align="center">
              <PlayerHeadshot
                className={"bg-transparent"}
                size="sm"
                playerId={player.nbaId}
              />
              {player.firstName} {player.lastName}
            </Flex>
          </Button>
        );
      })}
    </>
  );
}
