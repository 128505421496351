import { TeamRow } from "@/swagger";
import { Flex, Select, SelectOption } from "@suns/design-system";

export default function IntelSelect({
  selectedLeague,
  setSelectedLeague,
  selectedTeam,
  setSelectedTeam,
  leagues,
  teams,
}: {
  selectedLeague: string;
  setSelectedLeague: (league: string) => void;
  selectedTeam: TeamRow;
  setSelectedTeam: (team: TeamRow) => void;
  leagues: string[];
  teams: TeamRow[];
}) {
  return (
    <Flex gap="lg">
      <Select
        className="w-24 bg-white"
        value={selectedLeague}
        onValueChange={setSelectedLeague}
      >
        {leagues.map((league) => (
          <SelectOption key={league} value={league}>
            {league}
          </SelectOption>
        ))}
      </Select>
      <Select
        className="w-54 bg-white"
        value={selectedTeam?.nbaId.toString()}
        onValueChange={(value) => {
          const team = teams.find((team) => team.nbaId?.toString() === value);
          if (team) {
            setSelectedTeam(team);
          }
        }}
      >
        {teams?.map((team) => (
          <SelectOption key={team.nbaId} value={team.nbaId?.toString()}>
            {team.full_name}
          </SelectOption>
        ))}
      </Select>
    </Flex>
  );
}
