/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlayerMetadataRow } from './PlayerMetadataRow';
import type { TeamRow } from './TeamRow';
export type PlayerRow = {
    id: number;
    currentTeamId: number;
    currentTeam: TeamRow;
    collegeTeam: TeamRow;
    nbaId: number;
    ssId: number;
    dxId: number;
    firstName: string;
    lastName: string;
    birthDate: string;
    homeTown: string;
    homeState: string;
    homeCountryCode: string;
    nationalityCountryCode: string;
    highschool: string;
    level: PlayerRow.level;
    position: string;
    height: string;
    weight: number;
    wing: number;
    reach: number;
    metadata: PlayerMetadataRow;
    createdAt: string;
    updatedAt: string;
};
export namespace PlayerRow {
    export enum level {
        PRO = 'PRO',
        AMATEUR = 'AMATEUR',
    }
}

