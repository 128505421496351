import {
  ReportApolloGrade,
  ReportResponseItem,
} from "@suns/api/generated-client/apollo";
import { Badge, Button, cn, Flex, Text } from "@suns/design-system";
import {
  ApolloGradeValueLabels,
  ReportPositionLabels,
  ReportRoleLabels,
} from "../../reports-const";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { Link } from "react-router-dom";
import { URL } from "@/shared/utils/route-constant";

dayjs.extend(advancedFormat);

interface ReportListItemParams {
  report: ReportResponseItem;
  onUnpublish: (report: ReportResponseItem) => void;
}

function renderReportType(report: ReportResponseItem) {
  if (report.type === ReportResponseItem.type.GAME && report.opponentTeamId) {
    return `vs ${report.opponentTeam.full_name}`;
  } else if (report.type === ReportResponseItem.type.PHASE) {
    return "#-Game Phase Report";
  } else if (report.type === ReportResponseItem.type.MULTI_GAME) {
    return "#-Game Report";
  } else if (report.type === ReportResponseItem.type.GENERAL) {
    return "Intel Report";
  }
}

function renderCurrentScore(report: ReportResponseItem) {
  const currentScore = report.apolloGrades.find(
    (grade) => grade.scope === ReportApolloGrade.scope.CURRENT
  );
  if (currentScore) {
    return (
      <Badge
        className={cn("h-7 text-white", apolloGradeColor(currentScore.value))}
      >
        {ApolloGradeValueLabels[currentScore.value]}
      </Badge>
    );
  }

  return null;
}

function apolloGradeColor(grade: ReportApolloGrade.value) {
  switch (grade) {
    case ReportApolloGrade.value.FRANCHISE:
      return "bg-amber-400";
    case ReportApolloGrade.value.ALL_STAR_PLUS:
      return "bg-amber-500";
    case ReportApolloGrade.value.ALL_STAR:
      return "bg-orange-400";
    case ReportApolloGrade.value.STARTER:
      return "bg-orange-500";
    case ReportApolloGrade.value.TOP_ROTATION:
      return "bg-orange-600";
    case ReportApolloGrade.value.ROTATION:
      return "bg-fuchsia-700";
    case ReportApolloGrade.value.ROSTER:
      return "bg-purple-700";
    case ReportApolloGrade.value.MINOR_LEAGUE:
      return "bg-purple-900";
  }
}

function renderPosition(report: ReportResponseItem) {
  if (report.position) {
    return (
      <Badge className="h-7">{ReportPositionLabels[report.position]}</Badge>
    );
  }

  return null;
}

function renderRole(report: ReportResponseItem) {
  if (report.role) {
    return <Badge className="h-7">{ReportRoleLabels[report.role]}</Badge>;
  }

  return null;
}

function renderNotes(report: ReportResponseItem) {
  if (report.teamFitNotes) {
    return report.teamFitNotes;
  }

  return null;
}

export default function ReportListItem({
  report,
  onUnpublish,
}: ReportListItemParams) {
  return (
    <Flex direction="down" gap="sm">
      <Text size="md" heading muted>
        {renderReportType(report)}
      </Text>
      <Text size="2xl" heading>
        {report.player.firstName} {report.player.lastName}
      </Text>
      <Flex direction="right" gap="sm">
        {renderCurrentScore(report)}
        {renderPosition(report)}
        {renderRole(report)}
      </Flex>
      <>{renderNotes(report)}</>
      <Flex direction="right" gap="sm">
        {/* <Button variant="default">View</Button> */}
        <Button variant="outline">
          <Link
            to={`/${URL.REPORTS.path}/${URL.REPORTS.children.EDIT_REPORT.path}/${report.id}`}
          >
            Edit
          </Link>
        </Button>
        {report.status === ReportResponseItem.status.PUBLISHED ? (
          <Button variant="outline" onClick={() => onUnpublish(report)}>
            Unpublish
          </Button>
        ) : null}
      </Flex>
      <Flex direction="right" gap="xs">
        <Text as="strong">
          {dayjs(report.createdAt).format("MMMM Do YYYY")}
        </Text>
        <Text muted>by</Text>
        <Text as="strong">{report.authorName}</Text>
      </Flex>
    </Flex>
  );
}
