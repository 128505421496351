import { apolloApi, getGame } from "@/shared/api";
import { ReportResponseItem } from "@suns/api/generated-client/apollo";

export async function reportLoader({
  gameVendorId,
  authorUsername,
}: {
  gameVendorId: string;
  authorUsername: string;
}) {
  const game = await getGame({ gameId: gameVendorId });

  const reportsRequest = apolloApi.getReports({
    gameVendorId: gameVendorId,
    authorUsername: authorUsername,
  });

  const homeTeamRequest = apolloApi.getTeams({
    nbaId: `${game.homeTeam?.teamId}`,
  });

  const awayTeamRequest = apolloApi.getTeams({
    nbaId: `${game.awayTeam?.teamId}`,
  });

  const [reportsRes, homeTeams, awayTeams] = await Promise.all([
    reportsRequest,
    homeTeamRequest,
    awayTeamRequest,
  ]);

  const homeTeam = homeTeams && homeTeams.count > 0 ? homeTeams.teams[0] : null;
  const awayTeam = awayTeams && awayTeams.count > 0 ? awayTeams.teams[0] : null;

  const reports =
    reportsRes.reports?.reduce(
      (acc, report) => {
        acc[`${report.playerId}`] = report;
        return acc;
      },
      {} as Record<string, ReportResponseItem>
    ) ?? {};

  return { reports, game, homeTeam, awayTeam };
}
