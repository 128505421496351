// import { teams } from "@/shared/utils/teams";
import { Card, Grid, Skeleton, Text } from "@suns/design-system";
import { useGetTeams } from "./hooks/useGetTeams";
import { useEffect, useState } from "react";
import IntelSelect from "./components/IntelSelect";
import PlayerCards from "./components/PlayerCards";
import { PlayerRow, TeamRow } from "@/swagger";

export function IntelCreate() {
  const [selectedLeague, setSelectedLeague] = useState<string>("NBA");
  const { teamList, loading, error } = useGetTeams({ league: selectedLeague });
  const [selectedTeam, setSelectedTeam] = useState<TeamRow>();
  const [selectedPlayers, setSelectedPlayers] = useState<PlayerRow | null>(
    null
  );

  const leagues = ["NBA"];

  useEffect(() => {
    const filteredTeam = teamList?.find(
      (team) => team.nbaId.toString() === "1610612756"
    );
    setSelectedTeam(filteredTeam);
  }, [teamList]);

  return (
    <Card>
      <IntelSelect
        selectedLeague={selectedLeague}
        setSelectedLeague={setSelectedLeague}
        selectedTeam={selectedTeam as TeamRow}
        setSelectedTeam={setSelectedTeam}
        leagues={leagues}
        teams={teamList as TeamRow[]}
      />
      {error ? (
        <Text muted className="p-10 text-center">
          Sorry there was an error
        </Text>
      ) : (
        <Grid gap="lg" columns="4" className="mt-4 xs:max-xl:grid-cols-2">
          {loading ? (
            <>
              {[...Array(20).keys()].map((_, index) => (
                <Skeleton key={index} className="py-8" />
              ))}
            </>
          ) : (
            <PlayerCards
              players={selectedTeam?.currentPlayers as PlayerRow[]}
              selectedPlayers={selectedPlayers as PlayerRow}
              setSelectedPlayers={setSelectedPlayers}
            />
          )}
        </Grid>
      )}
    </Card>
  );
}
