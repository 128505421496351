import { loginRequest } from "@/msal.config";
import { msalInstance } from "./instance";
import { getAccount } from "./account";
import { AccountInfo } from "@azure/msal-browser";

export async function getAuthToken(): Promise<string | null> {
  const { info } = getAccount();

  if (!info) {
    return null;
  }

  try {
    const { idToken } = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: info,
    });

    if (!idToken) {
      return await acquireTokenPopup(info);
    }

    return idToken;
  } catch {
    return await acquireTokenPopup(info);
  }
}

async function acquireTokenPopup(account: AccountInfo) {
  try {
    const { idToken } = await msalInstance.acquireTokenPopup({
      ...loginRequest,
      account,
    });
    return idToken;
  } catch {
    return null;
  }
}
