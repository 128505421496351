import {
  PlayerRow,
  ReportApolloGrade,
  ReportPlayerAttribute,
  ReportResponseItem,
  ReportScore,
  ReportUpsertParams,
  TeamRow,
} from "@suns/api/generated-client/apollo";
import { GameReportFormSchema } from "./components/ReportForm/ReportForm";
import { AccountInfo } from "@azure/msal-browser";
import { ScoreboardGame } from "@suns/api/generated-client/stats";
import { ReportCanDefendKeys } from "./reports-const";

const defaultCanDefend = ReportCanDefendKeys.reduce<Record<string, "0">>(
  (acc, key) => {
    acc[key] = "0";
    return acc;
  },
  {}
);

export function reportResponseToFormData(
  report: ReportResponseItem | null,
  player: PlayerRow,
  author: AccountInfo,
  game: ScoreboardGame,
  homeTeam: TeamRow,
  awayTeam: TeamRow
): GameReportFormSchema {
  const scores =
    report?.scores.reduce<Record<string, string | null>>((acc, score) => {
      acc[score.key] = score.value != null ? `${score.value}` : "NA";
      return acc;
    }, {}) || {};

  return {
    id: report?.id ?? undefined,
    playerId: report?.playerId ?? player.id,
    teamId: report?.teamId ?? player.currentTeamId,
    opponentTeamId:
      report?.opponentTeamId ?? homeTeam.id === player.currentTeamId
        ? awayTeam.id
        : homeTeam.id,
    authorUsername: report?.authorUsername ?? author.username,
    authorName: report?.authorName ?? author.name,
    gameVendor: report?.gameVendor ?? ReportResponseItem.gameVendor.NBA,
    gameVendorId: game.gameId ?? undefined,
    status: report?.status ?? ReportResponseItem.status.UNPUBLISHED,
    level: report?.level ?? player.level,
    type: report?.type ?? ReportResponseItem.type.GAME,
    scoutLocation: report?.scoutLocation,
    position: report?.position ?? undefined,
    role: report?.role ?? undefined,
    teamFit: report?.teamFit ?? undefined,
    teamFitNotes: report?.teamFitNotes ?? undefined,
    offensiveNotes: report?.offensiveNotes ?? undefined,
    defensiveNotes: report?.defensiveNotes ?? undefined,
    otherNotes: report?.otherNotes ?? undefined,
    apolloGrades: report?.apolloGrades.reduce(
      (acc, grade) => {
        acc[grade.scope] = grade.value;
        return acc;
      },
      {} as Record<string, string>
    ),
    playerAttributes: report?.playerAttributes
      .filter((attr) => attr.value)
      .map((attr) => attr.key),
    scores: {
      ...defaultCanDefend,
      ...scores,
    },
  };
}

export function reportFormDataToUpsertParams(
  data: GameReportFormSchema
): ReportUpsertParams {
  return {
    ...data,
    authorUsername: data.authorUsername ?? "",
    apolloGrades: Object.entries(data.apolloGrades ?? {})
      .filter(([, value]) => !!value)
      .map(([scope, value]) => ({
        value,
        scope: scope as ReportApolloGrade.scope,
        numericalValue: Object.keys(ReportApolloGrade.value).indexOf(value) + 1,
        numericalMax: Object.keys(ReportApolloGrade.value).length,
      })),
    scores: Object.entries(data.scores ?? {})
      .filter(([, value]) => !!value)
      .map(([key, value]) => {
        return {
          key: key as ReportScore.key,
          value: value === "NA" ? undefined : Number(value),
          max: ReportCanDefendKeys.includes(key as ReportScore.key)
            ? 2
            : undefined,
        };
      }),
    playerAttributes: Object.values(ReportPlayerAttribute.key).map((key) => {
      return {
        key,
        value: data.playerAttributes?.includes(key) ?? false,
      };
    }),
    teamFitNotes: data.teamFitNotes ?? undefined,
    offensiveNotes: data.offensiveNotes ?? undefined,
    defensiveNotes: data.defensiveNotes ?? undefined,
    otherNotes: data.otherNotes ?? undefined,
    status: data.status,
  };
}
