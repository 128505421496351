import { apolloApi } from "@/shared/api";
import { ReportResponseItem } from "@suns/api/generated-client/apollo/models/ReportResponseItem";

interface MyReportsState {
  published: ReportResponseItem[];
  unpublished: ReportResponseItem[];
}

export async function myReportsLoader({
  authorUsername,
}: {
  authorUsername?: string;
}) {
  const response = await apolloApi.getReports({
    authorUsername: authorUsername ?? "",
  });

  const parsedReports = response.reports.reduce(
    (acc, report) => {
      if (report.status === ReportResponseItem.status.PUBLISHED) {
        acc.published = [...acc.published, report];
      } else {
        acc.unpublished = [...acc.unpublished, report];
      }
      return acc;
    },
    { published: [], unpublished: [] } as MyReportsState
  );

  return {
    ...parsedReports,
  };
}
