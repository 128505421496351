import { InfoIcon } from "lucide-react";
import {
  FormLabel,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  cn,
  Text,
} from "@suns/design-system";

interface FormLabelWithTooltipParams {
  title: string;
  tooltip?: string;
  className?: string;
}

export function FormLabelWithTooltip({
  title,
  tooltip,
  className,
}: FormLabelWithTooltipParams) {
  return (
    <FormLabel className={cn("flex flex-row items-center gap-2", className)}>
      {title}
      <HoverCard>
        <HoverCardTrigger asChild>
          <InfoIcon size={16} />
        </HoverCardTrigger>
        <HoverCardContent>
          <Text size="lg" heading>
            {title}
          </Text>
          <Text>{tooltip}</Text>
        </HoverCardContent>
      </HoverCard>
    </FormLabel>
  );
}
