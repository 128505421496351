import { LeagueId } from "@suns/api";
import { Flex } from "@suns/design-system";
import {
  cn,
  classVariants,
  type VariantParams,
  responsiveVariants,
} from "@suns/design-system/src/utils";

const sizes = {
  sm: "size-12",
  md: "size-16",
  lg: "size-24",
};

const playerHeadshotVariants = classVariants(
  ["rounded-full overflow-hidden bg-gray-200"],
  {
    variants: {
      size: {
        ...sizes,
        ...responsiveVariants("sm", sizes),
        ...responsiveVariants("md", sizes),
        ...responsiveVariants("lg", sizes),
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
);

type PlayerHeadshotProps = React.HTMLAttributes<HTMLDivElement> &
  VariantParams<typeof playerHeadshotVariants> & {
    playerId: number;
    leagueId?: LeagueId;
  };

export function PlayerHeadshot({
  playerId,
  leagueId,
  size,
  className,
  ...props
}: PlayerHeadshotProps) {
  let src: string;
  if (leagueId == LeagueId.Wnba) {
    src = `https://cdn.wnba.com/headshots/wnba/latest/1040x760/${playerId}.png`;
  } else if (leagueId == LeagueId.GLeague) {
    src = `https://ak-static.cms.nba.com/wp-content/uploads/headshots/gleague/1040x760/${playerId}.png`;
  } else {
    src = `https://cdn.nba.com/headshots/nba/latest/1040x760/${playerId}.png`;
  }
  return (
    <Flex
      align="end"
      justify="center"
      className={cn(playerHeadshotVariants({ size }), className)}
      {...props}
    >
      <img src={src} className="w-[120%] max-w-none" />
    </Flex>
  );
}
