import { Card, Flex, Text } from "@suns/design-system";
import { ChevronRight } from "@suns/design-system/icons";

import { useState } from "react";
import { useSchedule } from "./hooks/useSchedule";
import GameSelection from "./Components/GameSelection";
import ReportTypeSelection from "./Components/ReportTypeSelection";
import LeagueSelect from "./Components/LeagueSelect";
import { DatePicker } from "@suns/design-system/src/components/DatePicker/DatePicker";
import { useNavigate } from "react-router-dom";

const leagueList = ["NBA"];

interface Game {
  gameId: string;
  gameTime: string;
  homeTeamId: number;
  awayTeamId: number;
  homeTeam: string;
  awayTeam: string;
  arena: string;
}

export function ReportsCreate() {
  const [date, setDate] = useState<Date>(new Date());
  const {
    schedule,
    loading: scheduleLoading,
    error: scheduleError,
  } = useSchedule(date.toISOString());
  const [selectedLeague, setSelectedLeague] = useState<string>(leagueList[0]);
  const [selectedReportType, setSelectedReportType] = useState<string>("Game");
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const navigate = useNavigate();

  const handleReportTypeChange = (reportType: string) => {
    setSelectedReportType(reportType);
  };

  const handleSelectGame = (game: Game) => {
    setSelectedGame(game);
    navigate(`game/${game.gameId}`);
  };

  return (
    <>
      <Flex className="mb-4 mt-1" align="center">
        <Text size="xs" variant="muted">
          Report
        </Text>
        <ChevronRight className="text-gray-500 " size={20} />
        <Text size="xs" variant="muted">
          Create Report
        </Text>
      </Flex>
      <Text heading className="mb-4 text-gray-600">
        Select Report Type
      </Text>
      <ReportTypeSelection
        handleReportTypeChange={handleReportTypeChange}
        selectedReportType={selectedReportType}
      />
      <Text heading className="mb-4 text-gray-600">
        Choose Game
      </Text>
      <Card className="h-full">
        <Flex gap="md">
          {selectedReportType === "Game" ? (
            <DatePicker date={date} setDate={setDate} />
          ) : null}
          <LeagueSelect
            leagueList={leagueList}
            selectedLeague={selectedLeague}
            setSelectedLeague={setSelectedLeague}
          />
        </Flex>
        {selectedReportType === "Game" ? (
          <GameSelection
            selectedGame={selectedGame}
            handleSelectGame={handleSelectGame}
            schedule={(schedule as Game[]) || []}
            loading={scheduleLoading}
            error={scheduleError || ""}
          />
        ) : null}
      </Card>
    </>
  );
}
